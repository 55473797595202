





































































































































































import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import { request, Api } from '@/edshed-common/api'
import { ExternalCurriculumNodeInfo, PagedResults, TableState } from '@/edshed-common/api/types'
import ModalMixin from '@/edshed-common/mixins/ModalMixin'

import ComponentHelperBase from '@/edshed-common/mixins/ComponentHelperBase'

@Component({})
export default class ExternalCurriculumNodesView extends mixins(ModalMixin, ComponentHelperBase) {
  private heading = 'External Curriculum Nodes'

  private tableState: TableState = {
    page: 1,
    perPage: 10,
    sort: 'id',
    dir: 'asc',
    term: ''
  }

  private results: PagedResults<ExternalCurriculumNodeInfo> = {
    items: [],
    total: 0
  }

  private record: Partial<ExternalCurriculumNodeInfo> | null = null

  public mounted () {
    this.loadData()

    this.$watch(() => this.$route, () => this.loadData())
    this.$watch(() => this.$store.state.user, () => this.loadData())
  }

  private async loadData () {
    const curriculum_id = this.numberParam('curriculum_id', false)
    let parent_id: number | null | undefined = this.numberParam('parent_id', false)

    if (curriculum_id) {
      // Little hack to show only direct descendants
      parent_id = null

      const curriculum = await Api.getExternalCurriculum(curriculum_id)

      this.heading = `${curriculum.name} Children`
    } else if (parent_id) {
      const parent = await Api.getExternalCurriculumNode(parent_id)
      this.heading = this.ellipsis(`${parent.curriculum_name} > ${parent.qualified_name} Children`, 50)
    }

    this.results = await Api.getExternalCurriculumNodes({ curriculum_id, parent_id }, this.tableState)
  }

  private onPageChange (page: number) {
    this.tableState.page = page

    this.loadData()
  }

  private onSortChange (col: string) {
    const { sort, dir } = this.tableState

    if (sort !== col) {
      this.tableState.sort = col
      this.tableState.dir = 'asc'
    } else {
      this.tableState.dir = dir === 'asc' ? 'desc' : 'asc'
    }

    this.loadData()
  }

  private onSearchChange () {
    this.loadData()
  }

  private addRow () {
    const curriculum_id = this.numberParam('curriculum_id', false)
    const parent_id = this.numberParam('parent_id', false)

    if (curriculum_id) {
      this.record = {
        curriculum_id,
        parent_id: null,
        name: '',
        description: ''
      }
    } else if (parent_id) {
      this.record = {
        parent_id,
        name: '',
        description: ''
      }
    } else {
      this.record = {
        name: '',
        description: ''
      }
    }
  }

  private onEditRow (row: ExternalCurriculumNodeInfo) {
    this.record = row
  }

  private async onDeleteRow (row: ExternalCurriculumNodeInfo) {
    try {
      if (await this.deleteConfirm(row)) {
        await Api.deleteExternalCurriculumNode(row.id)

        this.loadData()
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        await this.alert({
          title: 'Error',
          message: err.message
        })
      }
    }
  }

  private onCloseEdit () {
    this.record = null
  }

  private async onSaveEdit () {
    try {
      await Api.saveExternalCurriculumNode(this.record as ExternalCurriculumNodeInfo)

      this.onCloseEdit()
      this.loadData()
    } catch (err: unknown) {
      if (err instanceof Error) {
        await this.alert({
          title: 'Error',
          message: err.message
        })
      }
    }
  }
}
